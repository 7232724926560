import React, { useContext, useState } from 'react';

export type FeatureShowcaseOverlayProviderContextData = {
  backgroundImage: string | null;
  setBackgroundImage: (backgroundImage: string | null) => void;
};

export const FeatureShowcaseOverlayProviderContext = React.createContext<FeatureShowcaseOverlayProviderContextData | undefined>(undefined);
FeatureShowcaseOverlayProviderContext.displayName = 'FeatureShowcaseOverlayProviderContext';

type FeatureShowcaseOverlayProviderProps = { children?: React.ReactNode };
const FeatureShowcaseOverlayProvider: React.FC<FeatureShowcaseOverlayProviderProps> = ({ children }) => {
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  return (
    <FeatureShowcaseOverlayProviderContext.Provider value={{ backgroundImage, setBackgroundImage }}>
      {children}
    </FeatureShowcaseOverlayProviderContext.Provider>
  );
};
FeatureShowcaseOverlayProvider.displayName = 'FeatureShowcaseOverlayProvider';

export default FeatureShowcaseOverlayProvider;

export const useFeatureShowcaseOverlay = () => {
  const context = useContext(FeatureShowcaseOverlayProviderContext);
  if (!context) {
    throw Error(`Missing required context`);
  }
  return context;
};
