// IMPORTANT: This file is used to map the image assets to their respective file names.
import { DigitalOceanCDN, isProduction } from './constants';

// Please upload all assets to the CDN before using them.
const ImageAssetsMapping = {
  'gradient-disc-green': '881c0929702258df43e00d4a3da4a7f9.svg',
  'gradient-disc-blue': 'ec83c2ec9e76a3abf83a958ac0abfeb4.svg',
  'benefit-one-click-automation': 'd088434ec1e14b0b8598884e6f5dc78c.svg',
  'benefit-human-like-answers': '5a9bf7be65064a0ba4d25072f8938afc.svg',
  'benefit-reduce-manual-work': '245a5995007c4866b8b507646eb3aa20.svg',
  'benefit-fast-resolution': '33d3ade80ebb4233af52362d2380b8c7.svg',
  'benefit-lower-cost': '0158a877e0814801b7f6b3ac1d67905a.svg',
  'benefit-insights': '4b43988be2a34f46952f69c11b471386.svg',
  'benefits-page-why-gaspar-1': '26dd132394ea47eeb8aa3a37f832948a.png',
  'benefits-page-why-gaspar-2': '33306c1dc1614cd78cec75e82e94fdd2.png',
  'benefits-page-why-gaspar-3': '4bf5fcaaef3d46239d4b0484c931146d.png',
  'benefits-page-why-gaspar-4': 'bebc17b6d1c843d7820eee2a9f32f319.png',
  'benefits-page-why-gaspar-5': '21fabfe7047e490988b56654baee1719.png',
  'benefits-page-why-gaspar-6': 'b13079c78c8449e3bb402e291a5af218.png',
  'benefits-page-why-gaspar-7': '1095ef0a7a354100a76ae7f3dd16c56e.png',
  'benefits-page-showcase-demo': '5de09377788e4561a630e9090915f7fa.gif',
  'platform-page-admin-portal': '18ceaf7e9424455d98c0eb2e40357b03.svg',
  'platform-page-automations': 'baa11f2f6fdd4f8094e3693d0476d92d.gif',
  'platform-page-generative-ai-virtual-agent': 'ef74b28b7aaf4e92b9395eab9998104b.svg',
  'platform-page-no-code-workflows': '849fe782d67047cbaf12213b1799ed81.svg',
  'platform-page-quick-look': 'e2d6e15fdb394c60b4dc8d57bbb2fe28.gif',
  'integrations-page-connect-seamlessly': 'a6e470ec29c14bc1855cd98575f82d5b.gif',
  'for-sales-page-express-approvals': 'c4343b271214494e8896fd239bb97b39.svg',
  'for-sales-page-personalised-info': 'bed79fedea1747e2afaf3e7cea78c66c.svg',
  'for-sales-page-instant-answers': '7210318930b44c249bddf11da595f995.svg',
  'for-it-page-email-list-management': 'adbf138524c34737a15e7f3bbd21392e.png',
  'for-it-page-folder-access-approvals': 'ba3990f5331346a99391956086811016.png',
  'for-it-page-group-management': 'd96d9ac3bc6949debb91004334e934a8.png',
  'for-it-page-hardware-provisioning': 'c527c736c6b749a6b6f354c3e5273265.png',
  'for-it-page-password-auto-reset': '5647c09979ee4d9a9b29d612644c9185.png',
  'for-it-page-software-provisioning': '2e7f52add99f44b08234b711955d67a9.png',
  'for-it-page-automated-workflows': '98cd5a486cc94ac9b0cd4a8effc18ce1.png',
  'for-it-page-clear-performance-metrics': 'b71c72882c884e959937bef8a0ea95a4.png',
  'for-it-page-enchanced-knowledge-management': 'f5f84907f3ca4a0c9f48979feb7e8d6d.png',
  'for-it-page-impactful-business-insights': 'cdfb1de24c634e9e8488944b3fb95c55.png',
  'for-it-page-instant-answers': '801dc7042e3845bea91cfeae8cf61217.png',
  'for-it-page-jira': 'c0700789b6924629b4f763804d61fd07.png',
  'for-it-page-quick-approvals': '262c9ae95b014399a466f8215f5f42bf.png',
  'for-it-page-slack': '68633e57690a4bb5a81088341b2a59a5.png',
  'for-it-page-smart-automation': 'e2bbf15facc14db0b30b85339f1ec11d.png',
  'for-it-page-teams': '096b63b24158454f9937d9d45a074492.png',
  'for-it-page-ticketing-made-easy': 'a23ff1db87634f63ba47974ef2e9e6c0.png',
  'for-hr-page-express-approvals': 'ab304223ce87463fbe6279f4c6ac70ef.png',
  'for-hr-page-instant-answers': '4ddb7eeb074a40f29f57169f26146448.png',
  'for-hr-page-onboarding': '0b16823659554eb4bc6478c88b43afd6.png',
  'for-hr-page-jira': '57722ff5999846b3b39a5ae7f4efb93f.png',
  'for-hr-page-slack': 'b1f425cf44f0447c99324fc910353a03.png',
  'for-hr-page-teams': '8400ebd21ac544fc8a0982e2fdcec23b.png',
  'for-hr-page-time-off-request': '653fc1fa3db24060a3130594aed83724.png',
  'for-hr-page-swift-onboarding': 'c91a71d430364c58a0c21d1fa5e9ca52.png',
  'for-hr-page-smart-automation': 'e1328a9370954e6293b78c5f35f87e80.png',
  'for-hr-page-pto-balance-request': '04487bdf0ac64a3bb22f5a40c494393f.png',
  'for-hr-page-personalised-info': 'ce1844ed01174a92a95cc1f018ff0f67.png',
  'free-trial-page-hero': '942d347e4b5e4108b3ae824186f2e987.png',
  'contact-page-hero': '92d7bda9a6914c94a2d3d794047f1352.png',
  'request-demo-page-hero': '465040ede5f7499d808f4d41f7ec5686.png',
  'how-it-works-page-what-is-an-ai-helpdesk': 'e7f2e125e78a4b24a665d1204c993876.gif',
  'use-cases-page-jira-data-center': '04b3b3106b42472e8f298b0c0c0ed046.svg',
  'use-cases-page-employee-offboarding': '8c91c5e43df24dca86f12b73b22e4ab4.svg',
  'use-cases-page-employee-onboarding': '1c78641dba144ba1804dc27545414962.svg',
  'use-cases-page-employee-support-jira': 'ea13845e151a4c42a9e7f460d9077313.svg',
  'use-cases-page-hr-helpdesk': 'd0a2697018924e3a99dde1307350d980.svg',
  'use-cases-page-it-service-management': 'b999516381a24daabd6bd0cc6ad4a70d.svg',
  'use-cases-page-identity-access-management': '042266b641fd4d0895bc0fd6b906fb84.svg',
  'use-cases-page-private-secure-support': '47040f4a4dca4cfdb916c97c226ec74f.svg',
  'use-cases-page-self-service': '8516e5efefc64be4b603382e4885429e.svg',
  'portal-page-benchmark': '395a8b227c134e03b066bc21fe825f69.svg',
  'portal-page-customisation': 'a53629e33d624d08980d130ca07c6450.svg',
  'portal-page-dashboard': '7692551a3a964e0099479a0ad4f21cd2.svg',
  'portal-page-employee-insights': '3a69ab7599df4c868d1f7729b840e340.svg',
  'portal-page-recommendations': '2ddd788eadb74dfc90e3a1a482922fca.svg',
  'portal-page-reporting': '643f68bc30b44622b9680687dba79532.svg',
  'portal-page-user-management': 'b2fe8b5c00774b49916bd9acb05066b3.svg',
  'portal-page-workflows': '05cd45e98a754017b3b7068b4d8c8e33.svg',
  'portal-page-take-control-of-AI': '0a41629e26bc466a988e4d9957d0757d.gif',
  'portal-page-unleash-business-intelligence': 'aa45f13e7912496da6527d345ba961dd.gif',
  'home-page-accelerate-approvals': '6e67523d450f4f0aa1b55a777ac100ee.svg',
  'home-page-auto-resolve-issues': '5cae90f910864f04a3e936b104c26900.svg',
  'home-page-automate-processes': 'cfe59d34b27b43b888ce2440c5609c60.svg',
  'home-page-automate-ticketing': 'cf054f7bf7414d139f3a50dd8f31e34d.svg',
  'home-page-offer-instant-answers': 'e05f6619aa8f4728867d16c63a13610a.svg',
  'home-page-optimise-knowledge-management': 'e255c37d0b1d4aa38c6078556dd1c32a.svg',
  'home-page-jira': 'f9689216da224f6e9e1122cbcc213f14.png',
  'home-page-slack': 'aea89e22c2e143dbb9ca7998b3448a52.png',
  'home-page-teams': '55d57c36484745c8b0644d1251c5d826.png',
  'home-page-gen-ai-support': 'f585015e8a26464fb7651389bb824025.gif',
  'home-page-reduce-manual-work': '35f490fa293e4a248b5faee360769e87.gif',
  'about-us-hero': 'c568e245040ee58f4d41f7edf7499d80.png',
  'about-us-team-member-at': '0eb595a9e0cd400cad8e24735ea48607.png',
  'about-us-team-member-ck': '22606ec6514a4565898e29dcee5d9e7c.png',
  'about-us-team-member-cp': 'c364367295d64970b09d44cf4d05b3a8.png',
  'about-us-team-member-dm': '90e521451643458db24615bbd9bb628b.png',
  'about-us-team-member-gx': '3a5978113bc94d5ebbe27c88cb09f5cb.png',
  'about-us-team-member-ha': '6f7bd18957724ae3b0c66f9c0aa6d5f2.png',
  'about-us-team-member-mn': 'd5abb0ebea4745d79d01e671c822f0ff.png',
  'about-us-team-member-pp': '09f217e0a741425dbac6e80d431c50dd.png',
  'about-us-team-member-tm': '62659797cd054aeab0ac1bb293a2e680.png',
  'about-us-team-member-lv': 'f1f0c9d8b8b64e3f8e5e3b8f6d3b2b2a.png',
  'landing-page-atlassian-integration-that-works': 'e66036ac47e9bdaee75d153a04dcf3e0.svg',
  'landing-page-atlassian-measure-investments-impact': '838a448192a9af74be8875973db09eed.svg',
  'landing-page-atlassian-improve-security': '34d2b795a79bb517f48b86d985209458.svg',
  'landing-page-atlassian-unique-business-insights': '685fc24c90a89f0e53034b928e29db9e.svg',
  'landing-page-atlassian-available-atlassian-customers': '59906839ee84e0ce6f374a6e6ba22806.svg',
  'landing-page-atlassian-automate-repetitive-processes': '45a7133baadc554a48ad48899a10b241.png',
  'landing-page-atlassian-automate-ticket-creation': 'a9f316fbe54f1f0367ce41a69b44f766.png',
  'landing-page-atlassian-automatically-resolve-issues': 'b86d985b7934d25a20945879bb517f48.png',
  'landing-page-atlassian-customize-jira-service': 'a5a270038a8a000b1be48b2bd22eef75.svg',
  'landing-page-atlassian-offer-human-like-answers': 'a2700c9def7ee45fb49e0b77ff815d505.png',
  'landing-page-atlassian-optimize-confluence-knowledge': '1708947d7388ada2b72480eabe63a94f.png',
  'landing-page-freshservice-automate-ticket-creation': 'f3e0e66036ac47e9bdaee75d153a04dc.png',
  'landing-page-freshservice-automate-repetitive-processes': '1929eeda9af74be8875973db838a4480.png',
  'landing-page-freshservice-automatically-resolve-issues': 'b7934d25a20945879bb517f48b86d985.png',
  'landing-page-freshservice-available-for-all-customers': '629db9e85fc24c90a89fb9280e53034e.svg',
  'landing-page-freshservice-get-unique-business-insights': '622806839ee84e04a6e6bce6f375990a.svg',
  'landing-page-freshservice-measure-your-investment-impact': 'a9d2dff8811a428a85ede7c7d1ec6c84.svg',
  'landing-page-freshservice-offer-accurate-human-like-answers': '38a8a000b1be48b2bd22eef75a5a2700.png',
  'landing-page-freshservice-optimise-your-knowledge-base': 'aadc554a48ad48899a10b24145a7133b.png',
  'landing-page-freshservice-works-on-cloud-or-infrastructure': 'd7388ada2b72480eabe63a94f1708947.svg',
  'landing-page-slack-question-solved': '377bf95088bc43718e73c230a9e371ba.png',
  'landing-page-slack-about-tab': '301a9bf560a64632b7bcb9ac81470eb5.png',
  'landing-page-slack-home-tab': 'bdb4610244974487b882fa554388f9f3.png',
  'landing-page-slack-message-tab': '24afa516359947c3bf0de37480f8fa6a.png',
  'landing-page-slack-automated-approvals': 'e2c6b57bf4eb4d0ebb17ca3d846eecfd.png',
  'landing-page-slack-automated-resolution': 'e33ff04222a14ce68c5d53691788bcd6.png',
  'landing-page-slack-hassle-free-automated-ticketing': 'caf245d7bef94a0498b1532536ded111.png',
  'landing-page-slack-instant-accurate-human-like-answers': 'c9def7ee45fb49e0b77ff815d505f021.png',
  'landing-page-slack-no-code-workflow-automation': '7bf7e8c765cd491ca8be9e1e19413b98.png',
  'landing-page-slack-start-the-conversation': 'e8622bbe43e647f3be26a9cb633780ce.png',
  'landing-page-slack-ticket-creation': 'e54f1f0367ce41a69b44f7661f316fbe.png',
  'landing-page-microsoft-teams-automated-approvals': '9722c1b61f6447f5b5a78e8c38fbc110.png',
  'landing-page-microsoft-teams-automated-resolution': 'a9d4e65b875f4ffbb3635028563df582.png',
  'landing-page-microsoft-teams-hassle-free-automated-ticketing': '342cdf92b45d4ee083d8d156b9156748.png',
  'landing-page-microsoft-teams-instant-accurate-human-like-answers': '91c757c1fc5d4ab694d83ed562f8a86e.png',
  'landing-page-xence-ai-accurate-human-like-answers': '7f5803fa3e7e49708a38a4a549c9d757.png',
  'landing-page-xence-ai-all-information': 'a70231c6ca1f4735aa0d648ae9f94fb3.png',
  'landing-page-xence-ai-exclusive-jira-chatbot': 'b56238cf08fb4d4b9a20d24b5446c899.png',
  'landing-page-xence-ai-interactive-conversations': 'e21a3ebe16ca4644bcf30b354ad063ec.png',
  'landing-page-xence-ai-installation-step-1': 'e5f7499d808f4d41f7ec5686465040ed.png',
  'landing-page-xence-ai-installation-step-2': '31c6ca1f4735aa0d648ae9f94fb68646.png',
  'landing-page-xence-ai-installation-step-3': 'e7e49708a38a4a549c9d757a70231c6c.png',
  'landing-page-xence-ai-installation-step-4': 'b4d4b9a20d24b5446c899b56238cf08f.png',
  'landing-page-xence-ai-installation-step-5': '4644bcf30b354ad063ece21a3ebe16ca.png',
  'landing-page-xence-ai-installation-step-6': '4735aa0d648ae9f94fb3e5f7499d808f.png',
  'landing-page-xence-ai-installation-step-7': '7ec5686465040ede5f7499d808f4d41f.png',
  'landing-page-xence-ai-installation-step-8': 'e9f94fb3e5f7499d808f4d41f7ec5686.png',
  'landing-page-xence-ai-installation-step-9': '6465040ede5f7499d808f4d41f7ec568.png',
  'landing-page-xence-ai-installation-step-10': 'fb3e5f7499d808f4d41f7ec0ed499d80.png',
} as const;

type ImageAssetName = keyof typeof ImageAssetsMapping;

export class CDNAssets {
  public static getUrl(asset: ImageAssetName) {
    return `${DigitalOceanCDN}/${ImageAssetsMapping[asset]}`;
  }
}
