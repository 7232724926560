import classnames from 'classnames';
import dynamic from 'next/dynamic';
import React from 'react';

import FeatureShowcaseOverlayProvider from '../../../widgets/showcases/FeatureShowcase/FeatureShowcaseOverlayProvider';
import { PurpleHeroBackdrop } from '../../backdrops/HeroBackdrops';
import PurpleRayBackdrop from '../../backdrops/PurpleRayBackdrop';
import { BlueElectricGradientContainer, PurpleGradientContainer } from '../../backdrops/containers';

// Dynamically import with SSR disabled
const BlackToWhiteAnimatedContainer = dynamic(() => import('../../backdrops/blackToWhiteAnimatedContainer'), { ssr: false });
const FeatureShowcaseSection = dynamic(() => import('../../../widgets/showcases/FeatureShowcase/FeatureShowcaseSection'), { ssr: false });

export enum PageSectionType {
  White = 'white',
  Black = 'black',
  BlackToWhiteAnimated = 'black-to-white-animated',
  BlueElectric = 'blue-electric',
  BlueDark = 'blue-dark',
  Purple = 'purple',
  PurpleDiagonal = 'purple-diagonal',
  FeatureShowcaseOverlay = 'feature-showcase-overlay',
}

type PageSectionProps = {
  type?: PageSectionType;
  isNarrow?: boolean;
  isNarrowTop?: boolean;
  className?: string;
  children?: React.ReactNode;
  baclgroundImage?: string;
};
const PageSection: React.FC<PageSectionProps> = (props) => {
  const { type, className, isNarrow, isNarrowTop, children } = props;
  const innerElement = (
    <div
      className={classnames('max-w-screen-2xl px-[25px] md:px-[100px] mx-auto z-10', {
        'py-[100px] md:py-[200px]': isNarrow !== true && isNarrowTop !== true,
        'py-[20px] md:py-[50px]': isNarrow === true,
        'pb-[50px] md:pb-[100px]': isNarrowTop === true && isNarrowTop === true,
      })}
    >
      {children}
    </div>
  );
  if (type === PageSectionType.White) {
    return <section className={classnames('bg-pureWhite text-black', className)}>{innerElement} </section>;
  }
  if (type === PageSectionType.BlueElectric) {
    return (
      <BlueElectricGradientContainer className={classnames('text-pureWhite', className)}>
        <PurpleHeroBackdrop>{innerElement}</PurpleHeroBackdrop>
      </BlueElectricGradientContainer>
    );
  }
  if (type === PageSectionType.BlueDark) {
    return <section className={classnames('text-pureWhite bg-blueDark', className)}>{innerElement} </section>;
  }
  if (type === PageSectionType.Purple) {
    return <PurpleGradientContainer className={classnames('text-pureWhite', className)}>{innerElement} </PurpleGradientContainer>;
  }
  if (type === PageSectionType.PurpleDiagonal) {
    return <PurpleRayBackdrop className={classnames('text-pureWhite', className)}>{innerElement}</PurpleRayBackdrop>;
  }
  if (type === PageSectionType.BlackToWhiteAnimated) {
    return <BlackToWhiteAnimatedContainer>{innerElement}</BlackToWhiteAnimatedContainer>;
  }
  if (type === PageSectionType.FeatureShowcaseOverlay) {
    return (
      <FeatureShowcaseOverlayProvider>
        <FeatureShowcaseSection> {innerElement} </FeatureShowcaseSection>
      </FeatureShowcaseOverlayProvider>
    );
  }
  return <section className={classnames('text-pureWhite bg-black', className)}> {innerElement} </section>;
};
PageSection.displayName = 'PageSection';
export default PageSection;
