import classnames from 'classnames';
import Image from 'next/image';
import React from 'react';

import { CDNAssets } from '../../CDN';
import { GradientBackdropProps } from './types';

export const DarkHeroBackdrop: React.FC<GradientBackdropProps> = React.memo((props) => {
  const { enableShadow, className, children } = props;
  const style = enableShadow ? { boxShadow: `0px 50px 60px -30px rgba(0,0,0,0.75) inset, 0px -70px 50px -30px rgba(0,0,0,0.8) inset` } : {};
  return (
    <div className={classnames('relative overflow-hidden', className)} style={style}>
      <div className="relative z-10">{children}</div>
      <div className="static z-0 select-none" aria-hidden="true">
        <div className="absolute top-0 right-0 rotate-45 scale-[200%] select-none">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-[-10%] right-[40%] rotate-[-10deg] scale-[180%] select-none">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-0 left-0 rotate-[-130deg] scale-[200%] select-none">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
      </div>
    </div>
  );
});
DarkHeroBackdrop.displayName = 'DarkHeroBackdrop';

export const LightHeroBackdrop: React.FC<GradientBackdropProps> = React.memo((props) => {
  const { className, children } = props;
  return (
    <div className={classnames('relative overflow-hidden bg-pureWhite ', className)}>
      <div className="relative z-10 text-black">{children}</div>
      <div className="static z-0 select-none" aria-hidden="true">
        <div className="absolute top-[-10%] right-[45%] rotate-[-10deg] scale-[120%] select-none opacity-50">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-[0%] left-[5%] transform rotate-[-100deg] scale-[150%] opacity-60">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
      </div>
    </div>
  );
});
LightHeroBackdrop.displayName = 'LightHeroBackdrop';

export const PurpleHeroBackdrop: React.FC<GradientBackdropProps> = React.memo((props) => {
  const { className, children } = props;
  return (
    <div className={classnames('relative overflow-hidden', className)}>
      <div className="relative z-10">{children}</div>
      <div className="static z-0 select-none" aria-hidden="true">
        <div className="absolute top-0 right-0 rotate-45 scale-[200%] select-none">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-0 right-0 rotate-45 scale-[200%] select-none">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-0 right-0 rotate-45 scale-[200%] select-none">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-[-10%] right-[40%] rotate-[-10deg] scale-[180%] select-none">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-0 left-40 rotate-[150deg] scale-[220%]">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-0 left-40 rotate-[150deg] scale-[220%]">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-0 left-40 rotate-[150deg] scale-[220%]">
          <Image src={CDNAssets.getUrl('gradient-disc-blue')} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
      </div>
    </div>
  );
});
PurpleHeroBackdrop.displayName = 'PurpleHeroBackdrop';
