import React from 'react';
import styled, { keyframes } from 'styled-components';

type ContainerDivProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const ContainerDiv: React.FC<ContainerDivProps> = (props) => {
  return (
    <div className={props.className} style={props.style}>
      {props.children}
    </div>
  );
};
ContainerDiv.displayName = 'ContainerDiv';

const gradientAnimate = keyframes`
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
`;

export const PurpleGradientContainer: React.FC<ContainerDivProps> = (props) => {
  const style = {
    backgroundSize: '180% 180%',
    background: 'linear-gradient(232.61deg, #c055db 23.17%, #5397d6 128.23%)',
  };
  return <ContainerDiv {...props} style={style} />;
};
PurpleGradientContainer.displayName = 'PurpleGradientContainer';

export const PurpleGradientContainerAnimated = styled.div`
  background: linear-gradient(232.61deg, #c055db 23.17%, #5397d6 128.23%);
  background-size: 200%;
  animation: ${gradientAnimate} 2s ease infinite;
`;

export const BlueElectricGradientContainer: React.FC<ContainerDivProps> = (props) => {
  const style = {
    backgroundSize: '180% 180%',
    background: 'linear-gradient(180deg, #7e41ff 0%, #2b5ba4 100%)',
  };
  return <ContainerDiv {...props} style={style} />;
};
BlueElectricGradientContainer.displayName = 'BlueElectricGradientContainer';
