import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useMeasure } from 'react-use';

type PurpleRayBackdropProps = {
  className?: string;
  children?: React.ReactNode;
};
const PurpleRayBackdrop: React.FC<PurpleRayBackdropProps> = (props) => {
  const { className, children } = props;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [containerRef, { width, height }] = useMeasure();

  useEffect(() => {
    drawEllipsisInCanvas(canvasRef.current, 0);
  }, [width, height]);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const offset = e.clientY / 50 - e.clientX / 200;
    drawEllipsisInCanvas(canvasRef.current, offset);
  };
  return (
    <div className={classnames('relative overflow-hidden bg-[#2B5BA4]', className)} ref={containerRef} onMouseMove={handleMouseMove}>
      <div className="relative z-10">{children}</div>
      <div className="static z-0" aria-hidden="true">
        <div className="absolute top-0 left-0">
          <canvas ref={canvasRef} width={`${width}px`} height={`${height}px`} />
        </div>
      </div>
    </div>
  );
};
export default PurpleRayBackdrop;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

const drawEllipsisInCanvas = (canvasElement: HTMLCanvasElement, offset: number) => {
  const { width: canvasWidth, height: canvasHeight } = canvasElement.getBoundingClientRect();
  // Get the canvas element context
  const ctx = canvasElement.getContext('2d');
  // Reset any previous paints
  ctx.clearRect(0, 0, canvasWidth, canvasHeight);
  // Create a radial gradient
  const gradient = ctx.createRadialGradient(0, 0, 10, 100, 100, 500);
  gradient.addColorStop(0, '#AA63DA');
  gradient.addColorStop(0.6, '#A862DD');
  gradient.addColorStop(0.8, '#423194');
  gradient.addColorStop(1, '#2B5BA4');
  // Set the fill style for the rectangle
  ctx.fillStyle = gradient;
  // Set the transformations to skew the ellipsis
  ctx.setTransform(1, 0.8, 0.5, 1, 0, 0);
  ctx.translate(-600 + 10 * offset, -1100 + 10 * offset);
  ctx.scale(3, 3);
  // Draw the rectangle
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);
};
